
import { Col, Row, Form, Input, Select, Button, notification } from "antd";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { createVideoSegment, CreateVideoSegmentModel, GetVideoStream, useVideoSubmission, VideoSegment, VideoSubmission } from "../../services/KosatuppService";
import { MultiSlider, SliderValuesInterface } from "../videocomponents/MultiSlider";
import { SliderValues, VideoPlayer, VideoPlayerOptions } from "../videocomponents/VideoPlayer";
import { useHistory } from "react-router-dom";
import { GetSpaceFile, SpaceFolder } from "../../services/SpacesService";
import { DeleteVideoButton } from "../videocomponents/DeleteVideoButton";

interface EditVideoParams {
  id: string;
}


export const EditVideoView = (): JSX.Element => {
  const { id } = useParams<EditVideoParams>();
  const [videoSubmission, loading, setVideoSubmission] = useVideoSubmission(id);
  const [sliderValuesState, setSliderValuesState] = useState<SliderValues[]>([{ left: 0, right: 1 }]);
  const history = useHistory();
  if (loading) {
    return <Row justify="center">
      <Spinner animation="border" />
    </Row>
  }

  const getSliderValues = () => {
    return [...sliderValuesState];
  }

  const openNotification = (message: string) => {
    notification.open({
      message: 'Success!',
      description: message,
      onClick: () => {
        console.log('Notification Clicked!');
      },
      type: "success"
    });
  };


  const videoPlayerOptions: VideoPlayerOptions = {
    preloadVideo: videoSubmission!.canPreload,
    isPlaying: false,
    source: GetSpaceFile(videoSubmission!.lowQualityVideoFile, SpaceFolder.LQ),
    sliderValues: [],
    duration: Math.floor(videoSubmission!.originalVideoFileDuration),
    getSliderValues: getSliderValues,
  };



  const onMultiSliderChange = (sliders: SliderValuesInterface[]) => {
    let newSliderValuesState: SliderValues[] = [];
    sliders.forEach((slider, index) => {
      let sliderValue: SliderValues = {
        left: slider.value[0] / slider.max,
        right: slider.value[1] / slider.max,
      };
      newSliderValuesState.push(sliderValue);
    })
    setSliderValuesState(newSliderValuesState);
  }

  const onNameChange = (event: any) => {

    let newVideoSubmission = { ...videoSubmission } as VideoSubmission;
    newVideoSubmission.name = event.target.value;
    setVideoSubmission(newVideoSubmission);
  }


  const onGameTypeChange = (event: string) => {

    let newVideoSubmission = { ...videoSubmission } as VideoSubmission;
    newVideoSubmission.gameTypeId = parseInt(event);
    setVideoSubmission(newVideoSubmission);
  }

  const onSubmitCut = async (event: any) => {
    let videoSegments: VideoSegment[] = [];
    var sliderValues = getSliderValues();
    sliderValues.forEach((slider) => {
      let startFrom = videoSubmission!.originalVideoFileDuration * slider!.left;
      let duration = (videoSubmission!.originalVideoFileDuration * slider!.right) - startFrom;
      let videoSegment: VideoSegment = {
        startFrom: startFrom,
        duration: duration
      };
      videoSegments.push(videoSegment);
    });

    var formData: CreateVideoSegmentModel = {
      videoSubmissionId: videoSubmission!.videoSubmissionId,
      name: videoSubmission!.name,
      gameTypeId: videoSubmission!.gameTypeId!,
      videoSegments: [...videoSegments],
    };




    createVideoSegment(formData).then((message) => {
      openNotification(message);
      history.push("/videos");
    });
  }



  return (
    <Row >
      <Col span={24}>
        <Form onFinish={onSubmitCut}>

          <Row className="ContentPadding">
            <Col span={24} >
              <Row>
                <Col span={6}>
                  <Form.Item name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Name is required',
                      }
                    ]}>
                    <Input value={videoSubmission?.name} onChange={onNameChange} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="gameTypeId"
                    rules={[
                      {
                        required: true,
                        message: 'Game is required',
                      },
                    ]}>
                    <Select value={videoSubmission?.gameTypeId ? videoSubmission?.gameTypeId + "" : "Select game"} onChange={onGameTypeChange}>
                      <Select.Option value="1">Csgo</Select.Option>
                      <Select.Option value="2">Dota</Select.Option>
                      <Select.Option value="3">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Row justify="end" gutter={6}>
                    <Col>
                      <DeleteVideoButton videoSubmissionId={videoSubmission!.videoSubmissionId} />
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit" >Submit cut</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <VideoPlayer {...videoPlayerOptions} sliderValues={sliderValuesState} ></VideoPlayer>
            </Col>
          </Row>

          <Row style={{ marginBottom: 25 }} className="RowSpacing ContentPadding">
            <Col span={24}>
              <MultiSlider onChange={onMultiSliderChange} max={Math.floor(videoSubmission!.originalVideoFileDuration)} />
            </Col>
          </Row>

        </Form>
      </Col>
    </Row>
  );
};