import { Col, Row, Button, Input, notification } from "antd";
import TextArea from "rc-textarea";
import React from "react";
import { useState } from "react";
import { GameTypeEnum } from "../../../models/GameTypeEnum";
import { createYoutubePreview, createYoutubeVideo, useGetLatestYoutubePreview, useGetYoutubeEstimate } from "../../../services/KosatuppService";
import { Loader } from "../../loader/Loader";
import { VideoSettingsComponent } from "./VideoSettingsComponent";

export interface CreatePreviewProps {
    gameType: GameTypeEnum;
}


export const CreatePreview = (props: CreatePreviewProps): JSX.Element => {

    const durationEstimate = useGetYoutubeEstimate(props.gameType);
    const game = GameTypeEnum[props.gameType];
    const [createPreviewDisabled, setCreatePreviewDisabled] = useState<boolean>(false);
    const [youtubeUrlValue, setYoutubeUrlValue] = useState<string>("");
    const [maxVideoMinutes, setMaxVideoMinutes] = useState<number>(10);
    const [createYoutubeVideoDisabled, setCreateYoutubeVideoDisabled] = useState<boolean>(true);
    const [videoSettingsId, setVideoSettingsId] = useState<string>("");
    const [youtubeVideo, loading] = useGetLatestYoutubePreview(props.gameType);

    const onCreatePreview = async () => {
        setCreatePreviewDisabled(true);
        await createYoutubePreview(props.gameType,maxVideoMinutes,videoSettingsId);
        notification.open({
            message: 'Success!',
            description: "Youtubevideopreview queued!",
            type: "success"
        });
        setTimeout(() => {
            setCreatePreviewDisabled(false);
        }, 2500);
    }

    const onVideoMaxMinutesChange = (e: any) => {
        setMaxVideoMinutes(e.target.value);
    }


    const onYoutubeurlInputChange = (e: any) => {
        let value = e.target.value;
        setYoutubeUrlValue(value);
        if (value.toLowerCase().startsWith("https://www.youtube.com/watch?")) {
            setCreateYoutubeVideoDisabled(false);
        }
    }

    const onCreateYoutubeVideo = async (e: any) => {
        setCreateYoutubeVideoDisabled(true);
        await createYoutubeVideo(youtubeVideo!.youtubeVideoId, youtubeUrlValue)
        notification.open({
            message: 'Success!',
            description: "Youtubevideo created!",
            type: "success"
        });
        setYoutubeUrlValue("");
    }

    if (loading) {
        return <Loader size={12} />;
    }
    const creationDate = youtubeVideo?.creationDate?.toString()?.split("T")[0];
    const creationTime = youtubeVideo?.creationDate?.toString()?.split("T")[1]?.substr(0, 5);
    const creationDateTime = (creationDate ?? "") + " " + (creationTime ?? "");
    const description = youtubeVideo?.description?.split("\\n")?.map(x => "<div>" + x + "<br/></div>").join("");
    return (
        <Row style={{ marginBottom: "6px" }}>
            <Col span={24}>
                <Row>
                    <Col span={19}>
                        <Input value={youtubeVideo?.title} />
                    </Col>
                    <Col span={5}>
                        {creationDateTime}
                    </Col>
                    <Col span={24} >
                        <div placeholder="description-placeholder" className="ant-input" contentEditable='true' style={{ whiteSpace: "pre-line", width: "100%" }} dangerouslySetInnerHTML={{ __html: description ?? "" }} />
                    </Col>
                </Row>
                <Row>
                    
                </Row>
                <Row gutter={6}>
                    <Col span={4}>
                        <Input value={maxVideoMinutes} placeholder="max minutes" onChange={onVideoMaxMinutesChange} />
                    </Col>
                    <Col span={8}>
                        <VideoSettingsComponent onChange={(id) => setVideoSettingsId(id)}/>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" disabled={createPreviewDisabled} onClick={onCreatePreview} block={true}>Create {GameTypeEnum[props.gameType]} preview</Button>
                    </Col>
                    <Col span={4}>
                        <Input value={youtubeUrlValue} placeholder="youtubeurl" onChange={onYoutubeurlInputChange} />
                    </Col>
                    <Col span={4}>
                        <Button type="primary" disabled={createYoutubeVideoDisabled} onClick={onCreateYoutubeVideo} block={true}>Create {GameTypeEnum[props.gameType]} youtubevideo</Button>
                    </Col>
                </Row>
            </Col>
            <Col span={8} >
                <Row justify="center">
                    <Col span={24}  >
                        <video controls={true} width="100%" src={youtubeVideo?.spaceUrl}></video>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

