import { IeOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Space } from "antd";
import { getDiscordImage, useGetLoggedInUser, useIsUserLoggedIn } from "../../services/KosatuppService";
import { DiscordIcon } from "./DiscordIcon";

export interface CardCoverProps {
    src: string;
    loader: boolean;
    cutIcon: boolean;
}

export const DiscordLogin = (): JSX.Element => {


    const [isLoggedIn,isLoggedInLoading] = useIsUserLoggedIn();
    const [user,userLoading] = useGetLoggedInUser(isLoggedIn);

    if(isLoggedInLoading && userLoading)
    {
        return (<div></div>);
    }

    if (!isLoggedIn) {
        return (
            <a href="/api/OAuth/Login">
                <Button type="primary" icon={<DiscordIcon />} >
                    Login with discord
                </Button>
            </a>
        )
    }
    if(user==null)
    {
        return (<div></div>);
    }

    return (
        <Row>
            <Col span={24}>
                <Space>
                    <span style={{color:"rgba(255, 255, 255, 0.65)"}} >{user!.username}</span>
                    <Avatar src={getDiscordImage(user!.discordId,user!.avatar)} />
                </Space>
            </Col>
        </Row>
    );
};

