import { Col, Row } from "antd";
import "../../App.css";




export const NotMemberOfKosatuppView = (): JSX.Element => {




  return (
    <Row justify="center" className="contentPadding">
        <Col>
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                <p>You are not a member of the kosatupp discord.</p>
            </div>
        </Col>
    </Row>
  );
};


