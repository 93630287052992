
import { Col, Row } from "antd";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { useVideoSubmission } from "../../services/KosatuppService";
import { GetSpaceFile, SpaceFolder } from "../../services/SpacesService";
import { VideoComment } from "../videocomponents/admin/VideoComment";
import { Authenticated } from "../videocomponents/Authenticated";
import { DeleteVideoButton } from "../videocomponents/DeleteVideoButton";


interface VideoParams {
  id: string;
}


export const VideoView = (): JSX.Element => {
  const { id } = useParams<VideoParams>();
  const [videoSubmission, loading] = useVideoSubmission(id);
  if (loading) {
    return <Row justify="center">
      <Spinner animation="border" />
    </Row>
  }

  return (
    <Row justify="center" >
      <Authenticated>
        <Col span={24}>
          <Row style={{marginBottom:"50px"}}>
            <Col offset={20} span={20}>
              <DeleteVideoButton videoSubmissionId={videoSubmission!.videoSubmissionId} />
            </Col>
          </Row>

          <Row justify="center">
            <Col span={12}>
              <Row>
                <video
                  src={GetSpaceFile(videoSubmission!.cutVideoFile, SpaceFolder.Cut)}
                  width={"100%"}
                  loop={true}
                  controls={true}
                  autoPlay={true}
                />
              </Row>
              <Row>
                <Col span={24} >
                  <VideoComment videoSubmission={videoSubmission!} ></VideoComment>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Authenticated>
    </Row>
  );
};