import { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import axios, { AxiosResponse } from 'axios';
import { VideoSubmissionStatusEnum } from "../models/VideoSubmissionStatusEnum";
import { GameTypeEnum } from "../models/GameTypeEnum";
import { YoutubeVideo } from "../models/YoutubeVideo";
import { VideoUploadUrlResponse } from "../models/VideoUploadUrlResponse";
import { VideoSettings } from "../models/VideoSettings";


const kosaTuppConfig = {
    domain: window.location.origin,
    getVideo: "/api/Video/GetVideo/",
    getVideoStream: "/api/VideoFile/GetVideoStream/",
    getVideoThumbnail: "/api/VideoFile/GetVideoThumbnail/",
    createVideoSegment: "/api/Video/CreateVideoSegment/",
    createVideoSubmission: "/api/Video/CreateVideoSubmission/",
    isUserLoggedIn: "/api/OAuth/IsUserLoggedIn",
    isUserAdmin: "/api/OAuth/IsUserAdmin",
    getLoggedInUser: "/api/OAuth/GetLoggedInUser",
    getVideosByStatuses: "/api/Video/GetVideosByStatuses",
    getVideoUploadUrl: "/api/Video/GetVideoUploadUrl",
    getYoutubeEstimate: "/api/Video/GetYoutubeEstimate/",
    createYoutubePreview: "/api/Video/CreateYoutubePreview/",
    youtubeVideos: "/api/YoutubeVideos/",
    createYoutubeVideo: "/api/Video/CreateYoutubeVideo/",
    getLatestYoutubePreview: "/api/Video/GetLatestYoutubePreview/",
    getYoutubeVideos: "/api/Video/GetYoutubeVideos/",
    getUserYoutubeVideos: "/api/Video/GetUserYoutubeVideos/",
    setVideoSubmissionAdminStatus: "/api/Video/SetVideoSubmissionAdminStatus/",
    deleteVideoSubmission: "/api/Video/DeleteVideoSubmission/",
    videoSettings: "/api/VideoSettings/",
};


export interface VideoSubmission {
    videoSubmissionId: string;
    name: string;
    description: string;
    gameTypeId? : number;
    videoSubmissionStatusId: number;
    originalVideoFile: string;
    originalVideoFileDuration: number;
    lowQualityVideoFile: string;
    LowQualityVideoFileSize: number;
    canPreload: boolean;
    cutVideoFile: string;
    videoThumbnail: string;
    creationDate: Date;
    videoSegments: VideoSegment[];
    isDeniedByAdmin: boolean;
    adminComment: string;
}

export interface CreateVideoSegmentModel {
    videoSubmissionId: string;
    name: string;
    gameTypeId : number;
    videoSegments: VideoSegment[];
}

export interface User {
    userId: string;
    username: string;
    discordId: string;
    avatar: string;
    discriminator: string;
}





export interface VideoSegment {
    startFrom: number;
    duration: number;
}

const getFullPath = (path: string) => {
    return kosaTuppConfig.domain + path;
}


export const GetVideoThumbnail = (img: string) => {
    return getFullPath(kosaTuppConfig.getVideoThumbnail + img);
}

export const GetVideoStream = (video: string) => {
    return getFullPath(kosaTuppConfig.getVideoStream + video);
}

export const useVideoSubmission = (id: string): [VideoSubmission | null, boolean, Dispatch<SetStateAction<VideoSubmission | null>>] => {
    const [videoSubmission, setVideoSubmission] = useState<VideoSubmission | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        axios.get<VideoSubmission>(getFullPath(kosaTuppConfig.getVideo + id))
        .then(res => {
            setVideoSubmission(res.data);
            setLoading(false);
        })
    }, [id]);
    return [videoSubmission, loading,setVideoSubmission];
}

export const createVideoSegment = async (createVideoSegmentModel: CreateVideoSegmentModel): Promise<string> => {
     return await (await axios.post<string>(getFullPath(kosaTuppConfig.createVideoSegment),createVideoSegmentModel)).data;
}

export const createVideoSubmission = async (name:string,fileName:string,lastModified: Date): Promise<string> => {
    var data = {
        name : name,
        fileName:fileName,
        lastModified:lastModified,
    };
    return await (await axios.post<string>(getFullPath(kosaTuppConfig.createVideoSubmission),data)).data;
}




// export const useVideoSubmissions = () : [VideoSubmission[], boolean] => {
//     const [videoSubmissions, setVideoSubmissions] = useState<VideoSubmission[]>([]);
//     const [loading, setLoading] = useState<boolean>(false);
//     useEffect(() => {
//         setLoading(true);
//         axios.get<VideoSubmission[]>(getFullPath(kosaTuppConfig.getVideos))
//         .then(res => {
//             setVideoSubmissions(res.data);
//             setLoading(false);
//         })
//     },[]);
//     return [videoSubmissions,loading];
// }

export const useVideoSubmissionsByStatuses = (videoSubmissionStatusesId: VideoSubmissionStatusEnum[], getAsAdmin: boolean) : [VideoSubmission[], boolean] => 
{
    let queryString = "?videosubmissionstatusid=" + videoSubmissionStatusesId.join("&videosubmissionstatusid=");
    if(getAsAdmin===true)
    {
        queryString += "&getAsAdmin=true";
    }
    const [videoSubmissions, setVideoSubmissions] = useState<VideoSubmission[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [reloader,setReloader] = useState(0);
    
    useInterval(() => {
        if(reloader < 240)
        {
            setReloader(reloader+1);
        }
    },15000);


    useEffect(() => {
        if(reloader === 0)
        {
            setLoading(true);
        }
        axios.get<VideoSubmission[]>(getFullPath(kosaTuppConfig.getVideosByStatuses + queryString))
        .then(res => {
            setVideoSubmissions(res.data);
            setLoading(false);
        })
        return () => {
        }
    },[videoSubmissionStatusesId,reloader]);
    return [videoSubmissions,loading];
}

export const useYoutubeVideos = (getUserYoutubeVideos: boolean) : [YoutubeVideo[], boolean] => 
{

    var path = getUserYoutubeVideos ? kosaTuppConfig.getUserYoutubeVideos : kosaTuppConfig.getYoutubeVideos

    const [youtubeVideos, setYoutubeVideos] = useState<YoutubeVideo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        axios.get<YoutubeVideo[]>(getFullPath(path))
        .then(res => {
            setYoutubeVideos(res.data);
            setLoading(false);
        })
    },[]);
    return [youtubeVideos,loading];
}

export const useVideoSettings = () : [VideoSettings[],Dispatch<SetStateAction<VideoSettings[]>>, boolean] => 
{
    const [videoSettings, setVideoSettings] = useState<VideoSettings[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        axios.get<VideoSettings[]>(getFullPath(kosaTuppConfig.videoSettings))
        .then(res => {
            setVideoSettings(res.data);
            setLoading(false);
        })
    },[]);
    return [videoSettings,setVideoSettings,loading];
}

export const createVideoSetting = async (videoSetting: VideoSettings) : Promise<string> => 
{
    var response = await axios.post<string>(getFullPath(kosaTuppConfig.videoSettings),videoSetting);
    return response.data;
}


export const tooltipFormatter = (value: (number | undefined)) => {
    if(value!==undefined)
    {
        let milliseconds = Math.floor((value % 1000) / 100);
        let seconds = Math.floor((value / 1000) % 60);
        let minutes = Math.floor((value / (1000 * 60)) % 60);

        let minutesString = (minutes < 10) ? "0" + minutes : minutes;
        let secondsString = (seconds < 10) ? "0" + seconds : seconds;
        return minutesString + ":" + secondsString + "." + milliseconds;
    }
    return "";
}

// export const useIsUserLoggedIn2 = () : [boolean,Dispatch<SetStateAction<boolean>] => {

//     (window as any).isUserLoggedIn = false;

//     useEffect(() => {

//     })
//     return [(window as any).isUserLoggedIn]
// }


export const useIsUserLoggedIn = () : [boolean,boolean] => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [storedValue,setValue] = useLocalStorage<string>("isLoggedIn" ,"false")
    

    useEffect(() => {
        setLoading(true);
        axios.get<boolean>(getFullPath(kosaTuppConfig.isUserLoggedIn))
        .then(res => {
            setValue(res.data.toString() === "true" ? "true" : "false")
            setIsUserLoggedIn(res.data);
            setLoading(false);
        })
    },[storedValue]);
    return [isUserLoggedIn,loading];
}

export const useGetLoggedInUser = (isLoggedIn: boolean) : [User | null,boolean] => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if(isLoggedIn)
        {
            setLoading(true);
            axios.get<User>(getFullPath(kosaTuppConfig.getLoggedInUser))
            .then(res => {
                setUser(res.data);
                setLoading(false);
            })
        }
        
    },[isLoggedIn]);
    return [user,loading];
}

export const useIsUserAdmin = () : [boolean,boolean] => {
    const [isUserAdmin, setUserAdmin] = useState<boolean>(false);
    const [storedValue,setValue] = useLocalStorage<string>("isUserAdmin" ,"false")
    const [loading, setLoading] = useState<boolean>(true);
    

    useEffect(() => {
        setLoading(true);
        axios.get<boolean>(getFullPath(kosaTuppConfig.isUserAdmin))
        .then(res => {
            setValue(res.data.toString() === "true" ? "true" : "false")
            setUserAdmin(res.data);
            setLoading(false);
        })
    },[storedValue]);
    return [isUserAdmin,loading];
}


export const useVideoUploadUrl = (): [string | null, boolean] => {
    const [videoUploadUrl, setVideoUploadUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        axios.get<string>(getFullPath(kosaTuppConfig.getVideoUploadUrl))
        .then(res => {
            setVideoUploadUrl(res.data);
            setLoading(false);
        })
    }, []);
    return [videoUploadUrl, loading];
}



export const getVideoUploadUrl = (): Promise<AxiosResponse<VideoUploadUrlResponse>> => {

    return axios.get<VideoUploadUrlResponse>(getFullPath(kosaTuppConfig.getVideoUploadUrl))
}

export const useGetYoutubeEstimate = (gametype: GameTypeEnum): string => {
    const [estimate, setEstimate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        axios.get<string>(getFullPath((kosaTuppConfig.getYoutubeEstimate + gametype)))
        .then(res => {
            setEstimate(res.data);
            setLoading(false);
        })
    }, []);
    return estimate;
}


export const createYoutubePreview = async (gametype: GameTypeEnum,maxVideoMinutes: number,videoSettingsId:string) => {
    let data = {
        gametype : gametype,
        maxVideoDurationMinutes: maxVideoMinutes,
        VideoSettingsId: videoSettingsId
    }
    await axios.post<boolean>(getFullPath(kosaTuppConfig.youtubeVideos),data)
    return true;
}

export const deleteVideoSubmission = async (videoSubmissionId: string) => {
    await axios.delete<boolean>(getFullPath((kosaTuppConfig.deleteVideoSubmission + videoSubmissionId)))
    return true;
}

export const createYoutubeVideo = async (youtubeVideoId: string, youtubeVideoUrl: string) => {
    await axios.post<boolean>(getFullPath((kosaTuppConfig.createYoutubeVideo)),{
        youtubeVideoId : youtubeVideoId,
        youtubeUrl : youtubeVideoUrl,
    });
    return true;
}

export const setVideoSubmissionAdminStatus = async (videoSubmissionId: string, isDeniedByAdmin: boolean,adminComment:string) => {
    await axios.post<boolean>(getFullPath((kosaTuppConfig.setVideoSubmissionAdminStatus)),{
        videoSubmissionId : videoSubmissionId,
        isDeniedByAdmin : isDeniedByAdmin,
        adminComment : adminComment,
    });
    return true;
}


export const useGetLatestYoutubePreview = (gametype: GameTypeEnum): [YoutubeVideo | null,boolean] => {
    const [youtubeVideo, setYoutubeVideo] = useState<YoutubeVideo | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        axios.get<YoutubeVideo>(getFullPath((kosaTuppConfig.getLatestYoutubePreview + gametype)))
        .then(res => {
            setYoutubeVideo(res.data);
            setLoading(false);
        })
    }, []);
    return [youtubeVideo,loading];
}



export const getDiscordImage = (discordId: string, avatar: string) => {
    return ["https://cdn.discordapp.com/avatars/",discordId,"/",avatar,".jpg"].join("");
}


export const useLocalStorage = <T>(key: string, initialValue: T) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    };
    return [storedValue, setValue] as const;
  }


  export const useInterval = (callback:Function,delay:number) => {
    const savedCallback = useRef() as any;

    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
  
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, []);
  }