
import {  Row, Button } from "antd";
import "../../App.css";
import { LinkContainer } from "react-router-bootstrap";
import { VideoSubmissionStatusEnum } from "../../models/VideoSubmissionStatusEnum";
import { VideosContainer } from "../videocomponents/VideosContainer";
import { Authenticated } from "../videocomponents/Authenticated";
import { YoutubeVideosContainer } from "../videocomponents/YoutubeVideosContainer";

export const VideosView = (): JSX.Element => {

  return (
    <div className="ContentPadding">
      <Authenticated>
        <Row style={{ marginBottom: 25 }}>
          <LinkContainer to={"/createvideo/"}>
            <Button type="primary">Upload video</Button>
          </LinkContainer>
        </Row>
      </Authenticated>
      <VideosContainer linkPath={"/editvideo/"} header={"Requires attention from user"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.WaitingForCutByUser]}  />
      <VideosContainer disableLink={true} header={"Being processed by server"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.WaitingForUpload,VideoSubmissionStatusEnum.WaitingInQueueForCut]}  />
      <VideosContainer linkPath={"/video/"} header={"Your videos"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.ReadyForFinalVideoOutput]}  />
      <YoutubeVideosContainer getAsUser={true} header="Youtube videos you have contributed to" />
    </div>
  );
};