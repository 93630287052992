import { useState } from "react";
import { useIsUserAdmin, useIsUserLoggedIn, useLocalStorage } from "../../services/KosatuppService";
import { AppLayoutProps } from "../layout/AppLayout";

export interface AuthProps extends AppLayoutProps {
    requiresAdmin?: boolean | undefined;
}


export const Authenticated = (props: AuthProps): JSX.Element => {


    

    const [isUserLoggedIn,loading] = useIsUserLoggedIn();
    const [isUserAdmin,loadingIsUserAdmin] = useIsUserAdmin();

    if(loading)
    {
        return (null as any);
    }

    if(!isUserLoggedIn)
    {
        return (null as any);
    }

    if(props.requiresAdmin===true && !isUserAdmin)
    {
        return (null as any);
    }


    return (
        [props.children] as any
    );
};

