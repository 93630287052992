import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { Col, Row, Button, Space, Slider } from "antd";
import { useState } from "react";
import "../../App.css";
import { tooltipFormatter } from "../../services/KosatuppService";
import { Video, VideoState } from "./Video";

export interface VideoPlayerOptions {
    source: string;
    preloadVideo: boolean;
    isPlaying: boolean;
    sliderValues: SliderValues[];
    duration: number;
    getSliderValues?: () => SliderValues[];
}

export interface SliderValues {
    left: number;
    right: number;
}

export const VideoPlayer = (props: VideoPlayerOptions, sliderValues: SliderValues[]): JSX.Element => {

    const playIcon = <CaretRightOutlined />;
    const pauseIcon = <PauseOutlined />
    const [videoPlayerState, setVideoPlayerState] = useState<VideoPlayerOptions>(props);
    const [timeSlider, setTimeSlider] = useState(0);

    const [video, setVideo] = useState<VideoState>({
        duration: 0,
        loaded: false,
        reference: null
    });
    

    const onClickPlayPause = (e: any) => {
        var newState = { ...videoPlayerState };
        newState.isPlaying = !newState.isPlaying;
        if (newState.isPlaying) {
            video!.reference!.play();
            console.log("playing")
        }
        else {
            video!.reference!.pause();
        }
        setVideoPlayerState(newState);
    }
    
    const onTimeUpdate = () => {
        if(video.loaded)
        {
            setTimeSlider(video!.reference!.currentTime*1000)
            if(props?.getSliderValues && videoPlayerState.isPlaying)
            {
                var sliderValues = props!.getSliderValues();
                var duration = video!.reference!.duration;
                var currentTime = video!.reference!.currentTime;
                let inSegment = false;
                let distanceToSegment = 99999999;
                let distanceToSegmentIndex = -1;
                let endOfSegment = true;
                for(let i = 0;i !== sliderValues.length;i++)
                {
                    let left = sliderValues[i].left * duration;
                    let right = sliderValues[i].right * duration;
                    if(currentTime >= left && currentTime <= right)
                    {
                        inSegment = true;
                        return;
                    }
                    else{
                        if(left - currentTime < distanceToSegment && left > currentTime)
                        {
                            distanceToSegment = left - currentTime;
                            distanceToSegmentIndex = i;
                            endOfSegment = false;
                        }
                    }
                }
                if(!inSegment)
                {
                    //console.log("hej");
                    if(endOfSegment)
                    {
                        video!.reference!.currentTime = 1;
                        
                        //onTimeUpdate(video);
                    }
                    else{
                        video!.reference!.currentTime = (sliderValues[distanceToSegmentIndex].left+0.0001) * duration;
                    }
                }
            }
        }
    }

    const onTimeSliderChange = (e: any) => {
        if(video.loaded)
        {
            video!.reference!.currentTime = e/1000;
            setTimeSlider(e);
        }
    }



    const onVideoLoaded = (event: VideoState) => {
        let newState = {...event};
        console.log(newState);
        //newState!.reference!.ontimeupdate = onTimeUpdate;
        setVideo(newState);
    }

    return (
        <Row>
            <Col span={24}>
                <Row justify="center" className="">
                    <Col span={12}>
                        <Row justify="center" style={{marginBottom:5}}>
                            <Video onTimeUpdate={onTimeUpdate} preloadVideo={props.preloadVideo} source={videoPlayerState.source} width={800} loop={true} onLoaded={onVideoLoaded} />
                        </Row>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={12}>
                        <Row justify="center" style={{marginBottom:5}} >
                            <Space align="center">
                                <Button type="primary" icon={videoPlayerState.isPlaying ? pauseIcon : playIcon} shape="circle" onClick={onClickPlayPause}></Button>
                                {/* <Button type="primary" shape="circle"><ScissorOutlined /></Button> */}
                            </Space>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className="ContentPadding">
                    <Col span={24}>
                        <Slider tipFormatter={tooltipFormatter} className="VideoSliderColor" min={0} max={props.duration} value={timeSlider} onChange={onTimeSliderChange}></Slider>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

