
import { Badge, Card, Col, Row } from "antd";
import { GetVideoThumbnail, useVideoSubmissionsByStatuses } from "../../services/KosatuppService";
import "../../App.css";
import Meta from "antd/lib/card/Meta";
import { LinkContainer } from "react-router-bootstrap";
import { CardCover } from "./CardCover";
import { Loader } from "../loader/Loader";
import { VideoSubmissionStatusEnum } from "../../models/VideoSubmissionStatusEnum";
import { GetSpaceFile, SpaceFolder } from "../../services/SpacesService";
import { useHistory } from "react-router-dom";

export interface IVideosContainerProps {
  VideoSubmissionStatuses: VideoSubmissionStatusEnum[],
  header: string,
  disableLink?: boolean;
  linkPath?: string;
  getAsAdmin?: boolean;
}

export const VideosContainer = (props: IVideosContainerProps): JSX.Element => {
  const [videoSubmissions, loading] = useVideoSubmissionsByStatuses(props.VideoSubmissionStatuses, props.getAsAdmin ?? false);
  const history = useHistory();
  if (loading) {
    return (<Loader height={300} size={100} />);
  }
  if (videoSubmissions.length === 0) {
    return (null as any);
  }



  const onClickCard = (e: any, videoSubmissionId: string) => {
    if (props.disableLink) {
      return;
    }
    history.push(props.linkPath + videoSubmissionId);
  }




  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <h3 style={{ width: "100%" }} >{props.header}</h3>
          </Col>
        </Row>
        <Row gutter={6}   >
          {
            videoSubmissions.map((videoSubmission, index) => (
              <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 16 }} key={index + videoSubmission.videoSubmissionId}>
                <Badge status={"error"} count={videoSubmission.isDeniedByAdmin ? "!" : null} offset={[0, 0]}  >
                  <Card
                    onClick={(e) => onClickCard(e,videoSubmission.videoSubmissionId)}
                    id={videoSubmission.videoSubmissionId}
                    key={videoSubmission.videoSubmissionId}
                    bordered={true}
                    hoverable={!props.disableLink}
                    cover={<CardCover videoSubmissionStatusId={videoSubmission!.videoSubmissionStatusId} src={GetSpaceFile(videoSubmission?.videoThumbnail, SpaceFolder.Thumbnails)} />}
                  >
                    <Meta title={videoSubmission.name} description={videoSubmission.description} />
                  </Card>
                </Badge>
              </Col>
            ))
          }
        </Row>
      </Col>
    </Row>
  );
}