import { Layout, Row, Menu, Col } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Authenticated } from "../videocomponents/Authenticated";
import { DiscordLogin } from "../videocomponents/DiscordLogin";

export interface AppLayoutProps {
    children: React.ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
    return <Layout className="layout">
        <Header>
            <Row>
                <Col span={18}>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} >
                        <Menu.Item key="">
                            <Link to="/">
                                Kosatupp
                            </Link>
                        </Menu.Item>
                        <Authenticated>
                            <Menu.Item style={{ float: "right" }} key="/Videos">
                                <Link to="/videos">
                                    Videos
                                </Link>
                            </Menu.Item>
                        </Authenticated>
                    </Menu>
                </Col>
                <Col span={6}>
                    <Row justify="end">
                        <Col>
                            <DiscordLogin />
                        </Col>
                    </Row>
                </Col>
            </Row>


        </Header>

        <Row style={{ height: '25px' }} />

        <Layout>
            <Content>
                {children}
            </Content>
        </Layout>
        <Footer style={{ textAlign: 'center' }}>
        </Footer>
    </Layout >
}