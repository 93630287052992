import axios from 'axios';
import { createVideoSubmission, getVideoUploadUrl } from './KosatuppService';

export const spacesConfig =  {
    digitalOceanSpaces: 'https://kosatuppspaces.fra1.digitaloceanspaces.com/',
    bucketName: 'kosatuppspaces'
}

export const GetSpaceFile = (name:string,folder: SpaceFolder) => {
    return spacesConfig.digitalOceanSpaces + getFolder(folder) + name;
}



export enum SpaceFolder {
    Thumbnails,
    Raw,
    Cut,
    Youtube,
    LQ,
    Static,
}

export const getFolder = (folder:SpaceFolder) => {
    var folderName = SpaceFolder[folder];
    if(folder === SpaceFolder.Static)
    {
        return folderName + "/";
    }
    var env = window.location.hostname === "localhost" ? "Test" : "Prod";
    return env + "/" + folderName + "/";
}

export const uploadVideo = (data:any) => {

    var presignedUrl = getVideoUploadUrl();
    presignedUrl.then(response => {
        console.log(response.data)
        axios.put(response.data.url,data.file,
        {
            headers: {
                "Content-Type" : "video/mp4",
                "x-amz-acl" : "public-read"
            },
            onUploadProgress : (progress) => {
                let percent = Math.min(((progress.loaded / progress.total)*100),99.9);
                data.onProgress({percent: percent});
                console.log(progress)
            }
        }).then((err) => {
            createVideoSubmission(data.file.name,response.data.filename,data.file.lastModifiedDate).then(() => 
            {
                data.onProgress({percent: 100});
                data.onSuccess(err);
            });
        })
        }
    );
}