import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import { useState } from "react";
import { VideoSettings } from "../../../models/VideoSettings";
import { createVideoSetting, useVideoSettings } from "../../../services/KosatuppService";

export interface VideoSettingsProps {
    onChange?: (event: string) => void;
}


export const VideoSettingsComponent = (props: VideoSettingsProps): JSX.Element => {

    const [newVideoSetting, setNewVideoSetting] = useState('');
    var [videoSettings,setVideoSettings,loading] = useVideoSettings();

    const onVideoSettingsChange = (event: any) => {
        setNewVideoSetting(event.target.value);
    }

    const onChange = (id: string) => {
        if(props.onChange)
        {
            props.onChange(id);
        }
    }

    const addItem = async () => {
        let videoSetting : VideoSettings = {
            settings : newVideoSetting,
            videoSettingsId : ""
        }
        let response = await createVideoSetting(videoSetting);
        videoSetting.videoSettingsId = response;
        var newVideoSettings = [...videoSettings,videoSetting];
        setVideoSettings(newVideoSettings);
        setNewVideoSetting("");
    }

    return <Select
        onChange={onChange}
        style={{
            width: "100%",
        }}
        placeholder="custom dropdown render"
        dropdownRender={(menu) => (
            <>
                {menu}
                <Divider
                    style={{
                        margin: '8px 0',
                    }}
                />
                <Space
                    style={{
                        padding: '0 8px 4px',
                        width:"100%"
                    }}
                >
                    <Input
                        placeholder="Please enter videoSettings"
                        value={newVideoSetting}
                        onChange={onVideoSettingsChange}
                    />
                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                        Add item
                    </Button>
                </Space>
            </>
        )}
    >
        {videoSettings.map((item) => (
            <Select.Option key={item.videoSettingsId} value={item.videoSettingsId}>{item.settings}</Select.Option>
        ))}
    </Select>
};
