import { DeleteOutlined, ScissorOutlined } from "@ant-design/icons";
import { Col, Button, Row, Slider, Space } from "antd";
import React from "react";
import { useState } from "react";
import "../../App.css";
import { tooltipFormatter } from "../../services/KosatuppService";


export interface SliderOptions {
    onChange: (event: SliderValuesInterface[]) => void;
    max: number;
}


export interface SliderValuesInterface {
    min: number;
    max: number;
    defaultValue: [number, number];
    value: [number, number];
    range: Range;
}

interface Range {
    draggableTrack: boolean;
}


const getDefaultSlider = (max: number): SliderValuesInterface => {
    let slider: (SliderValuesInterface) = {
        min: 0,
        max: max,
        defaultValue: [0, max],
        value: [0, max],
        range: { draggableTrack: true },
    };
    return slider;
}


export const MultiSlider = (options: SliderOptions): JSX.Element => {

    let defaultSlider = getDefaultSlider(options.max);
    const [sliderState, setSliderState] = useState<SliderValuesInterface[]>([defaultSlider]);

    const addCut = (e: any) => {
        var slider = getDefaultSlider(options.max);
        var newSliderState = [...sliderState, slider]
        setSliderState(newSliderState);
        options.onChange(newSliderState);
    }

    const removeCut = (e: any) => {
        if(sliderState.length===1)
        {
            return;
        }
        let newSliderState = [...sliderState];
        newSliderState.pop();
        setSliderState(newSliderState);
        options.onChange(newSliderState);
    }

    const onChange = (handles: any, index: number, slider: SliderValuesInterface) => {

        slider.value = handles as [number, number];
        sliderState.splice(index, 1, slider);


        let newState = [...sliderState];
        setSliderState(newState);
        options.onChange(newState);
    }


    return (
        <Row>
            <Col span={24}>
                <Row style={{marginBottom:"15px"}}>
                    <Col span={24}>
                        {
                            sliderState.map((slider, index) => (
                                <Slider tipFormatter={tooltipFormatter} className="VideoSlider" onChange={(handles: any) => (onChange(handles, index, slider))} value={slider.value} min={slider.min} max={slider.max} range={slider.range} key={index} />
                            ))
                        }
                    </Col>
                </Row>
                <Row justify="center">
                    <Space align="center">
                        <Button
                            onClick={addCut}
                            type="primary"
                            shape="circle"
                            icon={<ScissorOutlined />}
                        ></Button>
                        <Button
                            onClick={removeCut}
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                        ></Button>
                    </Space>
                </Row>
            </Col>
        </Row>




    );
};

