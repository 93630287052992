
import { Badge, Button, Card, Col, Modal, Row } from "antd";
import { GetVideoThumbnail, useVideoSubmissionsByStatuses, useYoutubeVideos } from "../../services/KosatuppService";
import "../../App.css";
import Meta from "antd/lib/card/Meta";
import { LinkContainer } from "react-router-bootstrap";
import { CardCover } from "./CardCover";
import { Loader } from "../loader/Loader";
import { VideoSubmissionStatusEnum } from "../../models/VideoSubmissionStatusEnum";
import { GetSpaceFile, SpaceFolder } from "../../services/SpacesService";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { YoutubeVideo } from "../../models/YoutubeVideo";

export interface IYoutubeContainerProps {
  header: string,
  getAsUser: boolean;
}

export const YoutubeVideosContainer = (props: IYoutubeContainerProps): JSX.Element => {
  const [youtubeVideos, loading] = useYoutubeVideos(props.getAsUser);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedYoutubeVideo, setSelectedYoutubeVideo] = useState<YoutubeVideo | null>(null);
  const [embedUrl, setEmbedUrl] = useState("");

  const history = useHistory();
  if (loading) {
    return (<Loader height={300} size={100} />);
  }
  if (youtubeVideos.length === 0) {
    return (null as any);
  }



  const onClickCard = (e: any, youtubeVideo: YoutubeVideo) => {
    setSelectedYoutubeVideo({...youtubeVideo});
    setVisibleModal(true);
    var url = "//www.youtube.com/embed/" + getEmbedUrl(youtubeVideo.youtubeUrl) + "?autoplay=1";
    setEmbedUrl(url);
  }


  const getEmbedUrl = (youtubeurl: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = youtubeurl.match(regExp);

    return (match && match[2].length === 11) ? match[2] : null;
  }



  return (
    <Row>
      <Modal
          
          title={selectedYoutubeVideo?.title}
          style={{ top: 20 }}
          width={800}
          closable={true}
          keyboard={true}
          centered
          visible={visibleModal}
          destroyOnClose={true}
          okText = ""
          okType="primary"
          onCancel={() => {setVisibleModal(false)}}
          maskClosable={true}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={() => {setVisibleModal(false)}}>
            Close
            </Button>,
          ]}
        >
          <iframe allowFullScreen={true} width="100%" height="400px" src={embedUrl} />
      </Modal>


      <Col span={24}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <h3 style={{ width: "100%" }} >{props.header}</h3>
          </Col>
        </Row>
        <Row gutter={6}   >
          {
            youtubeVideos.map((youtubeVideo, index) => (
              <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 16 }} key={index + youtubeVideo.youtubeVideoId}>
                <Card
                  onClick={(e) => onClickCard(e,youtubeVideo)}
                  id={youtubeVideo.youtubeVideoId}
                  key={youtubeVideo.youtubeVideoId}
                  bordered={true}
                  hoverable={true}
                  cover={<CardCover src={youtubeVideo.youtubeThumbnailUrl} />}
                >
                  <Meta title={youtubeVideo.title}/>
                </Card>
              </Col>
            ))
          }
        </Row>
      </Col>
    </Row>
  );
}