import { Col, Row, Form,Checkbox,Button, notification } from "antd";
import TextArea from "rc-textarea";
import React from "react";
import { useState } from "react";
import { setVideoSubmissionAdminStatus, VideoSubmission } from "../../../services/KosatuppService";
import { Authenticated } from "../Authenticated";

export interface VideoCommentProps {
    videoSubmission: VideoSubmission;
}


export const VideoComment = (props: VideoCommentProps): JSX.Element => {

    const [videoSubmission, setVideoSubmission] = useState<VideoSubmission>(props.videoSubmission);

    const onChangeAdminComment = (e:any) => {
        let value = e.target.value;
        
        let newState = {...videoSubmission};
        newState.adminComment = value;
        setVideoSubmission(newState);
    }

    const onChangeIsDeniedByAdmin = (e:any) => {
        const value = e.target.checked;
        console.log(e.target.checked);
        let newState = {...videoSubmission};
        newState.isDeniedByAdmin = value;
        setVideoSubmission(newState);
    }

    const changeAdminStatus = async () => {
        await setVideoSubmissionAdminStatus(videoSubmission!.videoSubmissionId,videoSubmission!.isDeniedByAdmin,videoSubmission!.adminComment);
        notification.open({
            message: 'Success!',
            description: "Status and comment set!",
            type : "success"
        });
    }

    return (
        <Row>
            <Col>
                <Row>
                    <p>{videoSubmission.isDeniedByAdmin ? videoSubmission.adminComment : null}</p>
                </Row>
            </Col>
            <Authenticated requiresAdmin={true}>
                <Col span={24}>
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Checkbox className="checkbox" checked={videoSubmission.isDeniedByAdmin} onChange={onChangeIsDeniedByAdmin}>Deny this submission</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <TextArea style={{color:"black",width:"100%"}} value={videoSubmission.adminComment} onChange={onChangeAdminComment} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={changeAdminStatus} type="primary">Set status</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Authenticated>
        </Row>
    );
};

