import { Col, Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../../App.css";
import {  uploadVideo } from "../../services/SpacesService";


export const UploadVideoForm = (): JSX.Element => {

    //const [fileName, setFileName] = useState("asd");
    //const [uploadState] = useState(props)
    // const [url,loading] = useVideoUploadUrl();
    // const [fileState,setFileState] = useState<any>({file:null});
    // if(loading)
    // {
    //    // return (null as any);
    // }


    const fileProps = {
        name: 'file',
        multiple: true,
        showUploadList: true,
        headers : {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        //action: url+"",
    };



    const upload = (e:any) => {
        uploadVideo(e);
    }

    

    return (


        <Col span={12}>

            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
            >

                <Form.Item
                    name="video"
                    rules={[
                        {
                            required: true,
                            message: 'Please add a video!',
                        },
                    ]}
                >
                    <Upload.Dragger accept="video/mp4" method="PUT" {...fileProps} customRequest={upload}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{"Click or drag files to upload new videos"}</p>
                    </Upload.Dragger>
                </Form.Item>


            </Form>
        </Col>


    );
};

