import { Col, Row, Space } from "antd";
import { useGetYoutubeEstimate } from "../../services/KosatuppService";
import { GameTypeEnum } from "../../models/GameTypeEnum";

export interface TotalVideoContentDurationProps {
    gameType: GameTypeEnum;
}


export const TotalVideoContentDuration = (props: TotalVideoContentDurationProps): JSX.Element => {

    const durationEstimate = useGetYoutubeEstimate(props.gameType);
    const game = GameTypeEnum[props.gameType];

    return (
        <Row data-test="Parent" justify="center">
            <Col span={24}>
                <Space></Space>
                <Row>
                    <Col span={24}>
                        <Row justify="center">
                            <p className="contentPadding" style={{textAlign:"center",fontWeight:"bold",fontSize:20}}>
                                {game}
                            </p>
                        </Row>
                        <Row justify="center">
                            <p style={{textAlign:"center"}}>
                                {durationEstimate}
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

