import { Col, Row } from "antd";
import { GameTypeEnum } from "../../models/GameTypeEnum";
import { GetSpaceFile, SpaceFolder } from "../../services/SpacesService";
import { TotalVideoContentDuration } from "../videocomponents/TotalVideoContentDuration";
import { YoutubeVideosContainer } from "../videocomponents/YoutubeVideosContainer";


export const HomeView = (): JSX.Element => {

  var startPage = GetSpaceFile("Startpage.jpg", SpaceFolder.Static);

  const heroStyling: React.CSSProperties = {
    height: "calc(100vh - 63px)",
    overflow: "hidden",
    backgroundImage: `url(${startPage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    filter: "blur(0px)"
  }


  return (
    <Row>
      <Col span={24} className="RowSpacing">
        <Row style={{ overflow: "hidden", marginTop: "-26px" }}>
          <Col span={24}>
            <Row style={heroStyling}>
              <Col span={24}>
                <Row justify="center" align="middle">
                  <h1 style={{ fontSize: "150px", lineHeight: "calc(100vh - 64px)" }}>
                    Kosatupp
                  </h1>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row justify="center">
              <Col span={8}>
                <TotalVideoContentDuration gameType={GameTypeEnum.Dota} />
              </Col>
              <Col span={8}>
                <TotalVideoContentDuration gameType={GameTypeEnum.Csgo} />
              </Col>
              <Col span={8}>
                <TotalVideoContentDuration gameType={GameTypeEnum.Other} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify="center">
          <Col span={22}>
            <YoutubeVideosContainer getAsUser={false} header="" />
          </Col>
        </Row>
      </Col>

    </Row>
  );
};