import { Button, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteVideoSubmission } from "../../services/KosatuppService";
import { useHistory } from "react-router-dom";
import React from "react";

export interface DeleteVideoButtonProps {
    videoSubmissionId: string;
}

export const DeleteVideoButton = (props: DeleteVideoButtonProps): JSX.Element => {

    const history = useHistory();

    const onClick = async () => {
        await deleteVideoSubmission(props.videoSubmissionId)
        notification.open({
            message: 'Success!',
            description: "Video deleted!",
            type: "success"
        });
        history.push("/videos");
    }

    return (
        <Button icon={<DeleteOutlined/>} type="primary" danger={true} onClick={onClick}></Button>
    );
}