import { Row } from "antd";
import "../../App.css";
import { UploadVideoForm } from "../videocomponents/UploadVideoForm";

export interface CreateVideoSubmissionInterface {
  uploadPercentage: number;
  fileName: string;
  hasMovieBeenSubmitted: boolean;
}

export const CreateVideoSubmissionView = (): JSX.Element => {




  return (
    <Row justify="center" className="contentPadding">
        <UploadVideoForm></UploadVideoForm>
    </Row>
  );
};


