import React from "react";
import { Spin } from "antd";
import { LoadingOutlined, ScissorOutlined } from "@ant-design/icons";
import { VideoSubmissionStatusEnum } from "../../models/VideoSubmissionStatusEnum";


export interface CardCoverProps {
    src: string;
    videoSubmissionStatusId?: VideoSubmissionStatusEnum;
}

export const CardCover = (props: CardCoverProps): JSX.Element => {

    let icon = null as any;
    let blur = "";
    if(props.videoSubmissionStatusId === VideoSubmissionStatusEnum.WaitingForUpload || props.videoSubmissionStatusId === VideoSubmissionStatusEnum.WaitingInQueueForCut)
    {
        blur = "blur(3px)";
        icon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
    }
    else if(props.videoSubmissionStatusId === VideoSubmissionStatusEnum.WaitingForCutByUser)
    {
        blur = "blur(3px)";
        icon = <ScissorOutlined style={{fontSize:72}} />;
    }



    return (
        <div>
            <div className="CenterOverImageSpinner" >
                {/* <div style={{backgroundImage:props.src, minHeight:"184px",maxHeight:"184px", width:"100%"}}>
                    content
                </div> */}
                <img width="100%" style={{filter: blur, width:"100%"}}  src={props.src} alt="film"/>
                <div>
                    {icon ? (<Spin spinning={true} indicator={icon} />) : null as any}
                </div>
            </div>
        </div> 
    );
};

