import { Col, Row } from "antd";
import React, { useRef, useState } from "react";
import { Loader } from "../loader/Loader";


export interface VideoOptions {
    source: string;
    preloadVideo: boolean;
    width?: number;
    loop?: boolean;
    onLoaded?: (event: VideoState) => void
    onTimeUpdate?: (event: VideoState) => void
}

export interface VideoState {
    duration: number;
    loaded: boolean;
    reference: HTMLVideoElement | null;
}

export const Video = (props: VideoOptions): JSX.Element => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoSource, setVideoSource] = useState(props.source);
    const [hasPreloaded, setHasPreloaded] = useState(false);

    const onLoadedData = () => {
        let eventData: VideoState = {
            duration: videoRef!.current!.duration,
            loaded: true,
            reference: videoRef?.current ?? null
        }
        if (props.onLoaded != null) {
            props.onLoaded(eventData);
        }
    }

    const preloadVideo = async (source: string) => {
        setHasPreloaded(true);
        const res = await fetch(source);
        const blob = await res.blob();
        const currentTime = videoRef?.current?.currentTime ?? 0;
        const isPlaying = !videoRef?.current?.paused;
        console.log(videoRef);
        console.log("PRELOAD DONE");
        console.log(currentTime);
        setVideoSource(URL.createObjectURL(blob));
        if(isPlaying)
        {
            videoRef?.current?.play();
        }
        setTimeout(() => {
            if(videoRef.current !== null)
            {
                videoRef!.current!.currentTime = currentTime; //set time in a timeout because otherwise it wont set the time since I load a new video source.
            }
        },0);
    }

    if (!hasPreloaded && props.preloadVideo) {
        preloadVideo(props.source);
    }

    



    return (
            <Row className="CenterOverImageSpinner">
                <div style={{ position: "relative", zIndex: 1 }}>
                    <Loader size={100} />
                </div>
                <video
                    ref={videoRef}
                    src={videoSource}
                    width={props?.width}
                    loop={props?.loop}
                    onLoadedData={onLoadedData}
                    onTimeUpdate={(event: any) => {
                        if (props.onTimeUpdate) {
                            props!.onTimeUpdate({
                                duration: videoRef!.current!.duration,
                                loaded: true,
                                reference: videoRef?.current
                            });
                        }
                    }}
                    style={{ position: "relative", zIndex: 2, overflow: "inherit" }}
                />
            </Row>

    );
};

