
import {  Row, Button, Col } from "antd";
import "../../App.css";
import { VideoSubmissionStatusEnum } from "../../models/VideoSubmissionStatusEnum";
import { VideosContainer } from "../videocomponents/VideosContainer";
import { Authenticated } from "../videocomponents/Authenticated";
import { GameTypeEnum } from "../../models/GameTypeEnum";
import { CreatePreview } from "../videocomponents/admin/CreatePreview";
import { TotalVideoContentDuration } from "../videocomponents/TotalVideoContentDuration";

export const AdminView = (): JSX.Element => {

  return (
    <div className="ContentPadding">
      <Authenticated requiresAdmin={true}>
        <TotalVideoContentDuration gameType={GameTypeEnum.Csgo} />
        <TotalVideoContentDuration gameType={GameTypeEnum.Dota} />
        <TotalVideoContentDuration gameType={GameTypeEnum.Other} />
        <Row style={{marginBottom:"10px"}}>
          <Col>
            <Button type="primary" block={true}>
              <a target="_blank" href="https://studio.youtube.com/channel/UCj3QsMqvj2z8hkLd-ddA1og/videos/upload?d=ud">upload</a>
            </Button>
          </Col>
        </Row>
        <CreatePreview gameType={GameTypeEnum.Csgo} />
        <CreatePreview gameType={GameTypeEnum.Dota} />
        <CreatePreview gameType={GameTypeEnum.Other} />
        <VideosContainer getAsAdmin={true} linkPath={"/EditVideo/"} header={"CutState"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.WaitingForCutByUser]}  />
        <VideosContainer getAsAdmin={true} disableLink={true} header={"Being processed by server"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.WaitingForUpload,VideoSubmissionStatusEnum.WaitingInQueueForCut]}  />
        <VideosContainer getAsAdmin={true} linkPath={"/Video/"} header={"Finished videos"} VideoSubmissionStatuses={[VideoSubmissionStatusEnum.ReadyForFinalVideoOutput]}  />
      </Authenticated>
    </div>

  );
};