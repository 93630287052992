import 'antd/dist/antd.dark.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppLayout } from './components/layout/AppLayout';
import { HomeView } from './components/views/HomeView';
import { CreateVideoSubmissionView } from './components/views/CreateVideoSubmissionView';
import { EditVideoView } from './components/views/EditVideoView';
import { VideosView } from "./components/views/VideosView";
import { NotMemberOfKosatuppView } from './components/views/NotMemberOfKosatuppView';
import { VideoView } from './components/views/VideoView';
import { AdminView } from './components/views/AdminView';

function App() {
  return (
    <Router>
        <AppLayout>
          <Switch>
            <Route exact path="/" component={HomeView}></Route>
            <Route exact path="/Videos" component={VideosView}></Route>
            <Route exact path="/CreateVideo" component={CreateVideoSubmissionView}></Route>
            <Route exact path="/EditVideo/:id" component={EditVideoView}></Route>
            <Route exact path="/NotMemberOfKosatupp" component={NotMemberOfKosatuppView}></Route>
            <Route exact path="/Video/:id" component={VideoView}></Route>
            <Route exact path="/admin" component={AdminView}></Route>
          </Switch>
        </AppLayout>
    </Router>
  );
}

export default App;
