import React from "react";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


export interface LoaderProps {
    size: number;
    height?: number;
}

export const Loader = (props: LoaderProps): JSX.Element => {

    let height = props.height ?? 500;

    return (
        <Row align="middle" justify="center" style={{ height: height }}>
            <Col>
                <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: props.size }} />} />
                </div>
            </Col>
        </Row>
    );
};

